<template>
  <div class="d-flex flex-column full">
    <Nav v-bind:username="username" />
    <div class="d-flex bg-cloud flex-grow-1">
      <SideBar :items="sideBarItems" v-on:refresh="componentRefresh += 1" />
      <div class="container mb-5">
        <div v-if="this.$route.path === '/admin'">
          <Dashboard />
        </div>
        <router-view :key="componentRefresh" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/NavBar.vue";
import SideBar from "@/components/SideBar.vue";
import Dashboard from "@/components/Dashboard";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer,
    Nav,
    SideBar,
    Dashboard,
  },
  data() {
    return {
      componentRefresh: 0,
    };
  },
  computed: {
    username: function () {
      return this.$store.getters.username + "";
    },
    sideBarItems: function () {
      return [
        { text: "Dashboard", to: "/admin", icon: "bar-chart-fill" },
        { text: "Users", to: "/admin/users", icon: "people-fill" },
        { text: "Organizations", to: "/admin/organizations", icon: "building" },
        { text: "Nodes", to: "/admin/nodes", icon: "cpu-fill" },
        { text: "QREs", to: "/admin/qres", icon: "server" },
      ];
    },
  },
};
</script>
