<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  data: () => ({
    chartdata: {
      labels: ["Memory Usage"],
      datasets: [
        {
          label: "Agent 1",
          backgroundColor: "#99DBD7",
          data: [Math.floor(Math.random() * 100)],
        },
        {
          label: "Agent 2",
          backgroundColor: "#5F8F8c",
          data: [Math.floor(Math.random() * 100)],
        },
        {
          label: "Agent 3",
          backgroundColor: "#C3BD90",
          data: [Math.floor(Math.random() * 100)],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
  }),

  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
