<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="bar-chart-fill" />
        {{ $t("dashboard.title") }}
      </h1>
    </div>

    <b-row class="mt-4">
      <b-col class="mb-4" lg="4" md="12">
        <b-overlay :opacity="0.95" :show="loadingInfo" rounded="sm">
          <b-card
            border-variant="light"
            class="shadow-sm card-rounded"
            data-cy="card-users"
          >
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">{{ $t("dashboard.users") }}</p>
              <div class="round">
                <b-icon icon="people-fill" />
              </div>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0" data-cy="card-data">{{ users }}</p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col class="mb-4" lg="4" md="12">
        <b-overlay :opacity="0.95" :show="loadingInfo" rounded="sm">
          <b-card
            border-variant="light"
            class="shadow-sm card-rounded"
            data-cy="card-agents"
          >
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">{{ $t("dashboard.agents") }}</p>
              <div class="round">
                <b-icon icon="hdd-stack-fill" />
              </div>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0" data-cy="card-data">{{ agents }}</p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col class="mb-4" lg="4" md="12">
        <b-overlay :opacity="0.95" :show="loadingInfo" rounded="sm">
          <b-card
            border-variant="light"
            class="shadow-sm card-rounded"
            data-cy="card-qres"
          >
            <div class="d-flex align-items-center justify-content-between">
              <p class="mb-0 custom-card-title">{{ $t("dashboard.qres") }}</p>
              <div class="round">
                <b-icon icon="server" />
              </div>
            </div>
            <div class="mt-2">
              <p class="card-info mb-0" data-cy="card-data">{{ qres }}</p>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card border-variant="light" class="shadow-sm card-rounded">
          <div class="d-flex align-items-center justify-content-between">
            <p class="mb-0 custom-card-title">{{ $t("dashboard.cpuUsage") }}</p>
            <div class="round">
              <b-icon icon="cpu-fill" />
            </div>
          </div>
          <div class="mt-2">
            <LineChart />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col>
        <b-card border-variant="light" class="shadow-sm card-rounded">
          <div class="d-flex align-items-center justify-content-between">
            <p class="mb-0 custom-card-title">
              {{ $t("dashboard.memoryUsage") }}
            </p>
            <div class="round">
              <b-icon icon="cpu-fill" />
            </div>
          </div>
          <div class="mt-2">
            <BarChart />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LineChart from "./Charts/LineChart.vue";
import BarChart from "./Charts/BarChart.vue";

export default {
  name: "Dashboard",
  data() {
    return {
      qres: null,
      agents: null,
      users: null,
      loadingInfo: true,
    };
  },
  mounted() {
    this.managerInfo();
  },
  methods: {
    async managerInfo() {
      this.loadingInfo = true;
      try {
        const { data: agent } = await this.$http.get(
          this.$cfg.BASE_QRE_MANAGER_VERSION_URL + "/info"
        );

        const { data: users } = await this.$http.get(
          this.$cfg.BASE_IDENTITY_VERSION_URL + "/accounts-stats"
        );

        this.qres = agent.qres;
        this.agents = agent.agents;
        this.users = users;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loadingInfo = false;
      }
    },
  },
  components: { LineChart, BarChart },
};
</script>

<style scoped>
.round {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #99dbd7;
  color: #5f8f8c;
}

.card-info {
  font-size: 1.5rem;
  font-weight: 700;
  color: #6c757d;
  min-height: 36px;
}

.custom-card-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  color: #7e868b;
}

.logs {
  background-color: #141b2e;
}

.log-text {
  font-size: 0.9rem;
  color: #ecfaf4;
  margin-bottom: 0;
  line-height: 0.9rem;
}

.log-text-time {
  color: #788a70;
}
</style>
