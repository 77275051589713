<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  mounted() {
    this.renderChart(
      {
        labels: [
          "-50 mins",
          "-45 mins",
          "-40 mins",
          "-35 mins",
          "-30 mins",
          "-25 mins",
          "-20 mins",
          "-15 mins",
          "-10 mins",
          "-5 mins",
          "0 min",
        ],
        datasets: [
          {
            label: "Agent 1",
            data: Array.from({ length: 11 }, () =>
              Math.floor(Math.random() * 100)
            ),
            backgroundColor: "transparent",
            borderColor: "#99DBD7",
            pointBackgroundColor: "#99DBD7",
          },
          {
            label: "Agent 2",
            data: Array.from({ length: 11 }, () =>
              Math.floor(Math.random() * 100)
            ),
            backgroundColor: "transparent",
            borderColor: "#5F8F8c",
            pointBackgroundColor: "#5F8F8c",
          },
          {
            label: "Agent 3",
            data: Array.from({ length: 11 }, () =>
              Math.floor(Math.random() * 100)
            ),
            backgroundColor: "transparent",
            borderColor: "#C3BD90",
            pointBackgroundColor: "#C3BD90",
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
      }
    );
  },
};
</script>
